import { AppDispatch, RootState } from '@/projects/shuttle/store/store';
import { showToasterWithDelay } from '@/store/slices/toasterSlice';
import { axiosInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type tourSearchData = {
  from_address: AddressType;
  to_address: AddressType;
  phone: string;
  date?: string;
};

export type toursSearchResponse = {
  tours: tourType[];
  from_address: AddressType;
  to_address: AddressType;
};

export const searchToursAsync = createAsyncThunk<
toursSearchResponse,
tourSearchData,
{
  dispatch: AppDispatch;
  rejectValue: string;
}
>(
  'tours/SearchToursAsync',
  async (data: tourSearchData, { dispatch, rejectWithValue }) => {
    return await axiosInstance
      .post<toursSearchResponse>('/search/tours', data)
      .then((data): toursSearchResponse => data)
      .catch(() => {
        dispatch(
          showToasterWithDelay({
            type: 'warning',
            message: 'Ошибка при получении списка туров',
            isVisible: true,
          })
        );
        return rejectWithValue('Ошибка при получении списка туров');
      });
  }
);

type TourListType = {
  [key: string]: tourDataType[];
};

interface ToursState {
  tours: [] | tourDataType[];
  tourList: TourListType;
  pickupStopId: number | null;
  dropOffStopId: number | null;
}

const initialState: ToursState = {
  tours: [],
  tourList: {},
  pickupStopId: null,
  dropOffStopId: null,
};

export const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTours: (state, action) => {
      state.tours = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchToursAsync.fulfilled, (state, action) => {
      const response = action.payload as toursSearchResponse;
      const { tours, from_address, to_address } = response;

      if (tours.length) {
        // state.tours = tours.map((tour: tourType) => {
        //   return {
        //     from_address,
        //     to_address,
        //     tour,
        //   } as tourDataType;
        // });
        state.tourList = {};
        for (const tour of tours) {
          const tourData = { from_address, to_address, tour } as tourDataType;
          if (!(tour.route_desired_time in state.tourList)) {
            state.tourList[tour.route_desired_time] = [tourData];
          } else {
            state.tourList[tour.route_desired_time].push(tourData);
          }
        }
        state.pickupStopId = tours[0].pickup_bus_stop.id;
        state.dropOffStopId = tours[0].dropoff_bus_stop.id;
      }
    });
    builder.addCase(searchToursAsync.rejected, () => {});
  },
});

export const { setTours } = toursSlice.actions;

export const toursSelector = (state: RootState) => state.toursReducer;

export default toursSlice.reducer;
