import { RootState } from '@/shuttle/store/store';
import { axiosInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RouteKind } from '@/shuttle/constants';

type subsriptionData = {
  id: string;
};

export type SubscriptionInfoResponse = {
  route_name: string;
  route_kind: RouteKind;
  fake_left_capacity: number;
  whatsapp_group_link: string;
  valid_since_date: string;
  valid_until_date: string;
  pickup_point_name: string;
  delivery_point_name: string;
  pickup_point_departure_time: string;
  delivery_point_departure_time: string;
  driver: {
    name: string;
    car_name: string;
    car_number_plate: string;
    photo_url: string;
  };
};

export const getSubscriptionInfoAsync = createAsyncThunk(
  'subscription/getSubscriptionInfo',
  async (data: subsriptionData): Promise<SubscriptionInfoResponse> => {
    return await axiosInstance.get<SubscriptionInfoResponse>(`/subscriptions/${data.id}`).then((data) => data);
  }
);

export interface SubscriptionState {
  isLoading: boolean;
  data: SubscriptionInfoResponse | undefined | null;
}

const initialState: SubscriptionState = {
  isLoading: false,
  data: undefined,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setData: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionInfoAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptionInfoAsync.fulfilled, (state, action: PayloadAction<SubscriptionInfoResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getSubscriptionInfoAsync.rejected, (state) => {
      state.isLoading = false;
      state.data = null;
    });
  },
});

export const subscriptionSelector = (state: RootState) => state.subscriptionReducer;

export default subscriptionSlice.reducer;
