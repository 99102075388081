import MainLayout from '@/components/shared/layouts/MainLayout';
import React, { FC, lazy, useMemo } from 'react';

import { appNames } from './constants';
const FakeShuttleApp = lazy(() => import('./projects/fake_shuttle/App'));
const OnDemandShuttleApp = lazy(() => import('./projects/on_demand/App'));
const SchoolShuttleApp = lazy(() => import('./projects/school/App'));
const ShuttleApp = lazy(() => import('./projects/shuttle/App'));
const TravelShuttleApp = lazy(() => import('./projects/travel_shuttle/App'));
const MobileApp = lazy(() => import('./projects/mobile/App'));

const App: FC = (): React.ReactElement => {
  const app_name = import.meta.env.VITE_APP_NAME;

  const CurrentApp = useMemo(() => {
    switch (app_name) {
      case appNames.FAKE:
        return FakeShuttleApp;
      case appNames.SCHOOL:
        return SchoolShuttleApp;
      case appNames.TRAVEL:
        return TravelShuttleApp;
      case appNames.ONDEMAND:
        return OnDemandShuttleApp;
      case appNames.MOBILE:
        return MobileApp;
      default:
        return ShuttleApp;
    }
  }, [app_name]);

  return (
    <MainLayout>
      <CurrentApp />
    </MainLayout>
  );
};

export default App;
