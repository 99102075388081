import { DateType } from '@/@types/shuttle/date';
import { capitalizeFirstLetter } from './string';

export function getDateWeekdayString(date: Date): string {
  const formatter = new Intl.DateTimeFormat('ru', { weekday: 'long' });
  return capitalizeFirstLetter(formatter.format(date));
}

export function getDateDayString(date: Date): string {
  const formatter = new Intl.DateTimeFormat('ru', { day: 'numeric', month: 'numeric' });
  return formatter.format(date);
}

export function getDateDayFullMonthString(date: Date): string {
  const formatter = new Intl.DateTimeFormat('ru', { day: 'numeric', month: 'long' });
  return formatter.format(date);
}

export function getDateDayFullMonthWithWeekdayString(date: Date): string {
  return getDateDayFullMonthString(date) + ', ' + getDateWeekdayString(date).toLowerCase();
}

export function getDateString(date: Date): string {
  return getDateWeekdayString(date) + ' ' + getDateDayString(date);
}

export function getNextWeekday(weekday: number, date?: Date): Date {
  const today = date || new Date();
  const currentDayOfWeek = today.getDay();
  const daysToAdd = (weekday + 7 - currentDayOfWeek) % 7;

  const nextWeekday = new Date(today);
  nextWeekday.setDate(today.getDate() + daysToAdd);

  return nextWeekday;
}

export function getNextMonday(): Date {
  const today = new Date();
  const nextMonday = new Date(today.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7 || 7))); // Add 7 days to always get the next Monday
  return nextMonday;
}

export function getNextMondayAfterNextSaturday() {
  return getNextWeekday(1, getNextWeekday(6));
}

export function getNextMondayAfterNextSunday() {
  return getNextWeekday(1, getNextWeekday(7));
}

export const getDateValuePreview = (selectedDate: DateType | null | undefined): string => {
  if (selectedDate) {
    const date = selectedDate.value;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (today.toDateString() === date.toDateString()) {
      return 'сегодня';
    } else if (tomorrow.toDateString() === date.toDateString()) {
      return 'завтра';
    }
    return getDateDayFullMonthString(date);
  }

  return 'сегодня';
};
